<template>
  <div class="detail-wrap" :class='{web:!isMobile}'>
    <div class="title">{{ title }}</div>
    <div class="content" v-html="content"></div>
    <div class="down-btn" v-if="!isView && id">
      <van-button type="primary" @click="down" size="small">下载</van-button>
      <van-button
        type="primary"
        @click="copy"
        size="small"
        id="copy-btn"
        :data-link="copyLink"
        >分享链接(点我复制)</van-button
      >
    </div>
  </div>
</template>
<script>
import ClipboardJS from "clipboard";
export default {
  data() {
    return {
      id: null,
      words: [],
      text: "",
      title: "",
      warning: [],
      isView: false, //是否是内容，不需要审核
      isMobile:/Mobi|Android|iPhone/i.test(window.navigator.userAgent)
    };
  },
  computed: {
    content() {
      let temp = this.text;
      this.words.forEach((item) => {
        // let reg = new RegExp(`${item}`, "gi");
        temp = temp.replaceAll(item, `<span class='bad'>${item}</span>`);
      });
      this.warning.forEach((item) => {
        // let reg = new RegExp(`${item}`, "gi");
        temp = temp.replaceAll(item, `<span class='warning'>${item}</span>`);
      });
      return temp;
    },
    copyLink() {
      return `【小竹猫话术】${this.title} \n\r ${location.origin}${location.pathname}`;
    },
  },
  created() {
    console.log(">>>>", this.$route);
    if (this.$route && this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
      this.fetch();
    }
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.type === "check"
    ) {
      this.fetchWord();
      this.fetchWarningWord();
    } else {
      this.isView = true;
    }
  },
  methods: {
    async fetch() {
      let ret = await this.$http.get(`/transcript/${this.id}`);
      this.title = ret.title;
      this.text = ret.body;
      let temp = this.text.split(/[\n\r]/);
      this.text = temp.map((item) => `<p>${item}</p>`).join("");
      console.log(">>>> content ", this.text.split(/[\n\r]/));
    },
    async fetchWord() {
      let data = await this.$http.get(`/bad-words`);
      this.words = data;
    },
    async fetchWarningWord() {
      let data = await this.$http.get(`/warning-words`);
      this.warning = data;
    },
    down() {
      let downLink = document.createElement("a");
      downLink.download = `${this.title}.txt`;
      let blob = new Blob([`${this.title}\n ${this.text}`]);
      downLink.href = URL.createObjectURL(blob);
      document.body.appendChild(downLink);
      downLink.click();
      document.body.removeChild(downLink);
    },
    copy() {
      var clp = new ClipboardJS("#copy-btn", {
        text: function (trigger) {
          return trigger.getAttribute("data-link");
        },
      });
      clp.on("success", () => {
        this.$toast("拷贝成功");
        clp.destroy();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-wrap {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  flex: 1;
  box-sizing: border-box;
  overflow-y: auto;
  &.web{
    .title{
      font-size: 30px;
    }
    .content{
      :deep(p){
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.5em;
      }
    }
  }
  .title {
    font-size: r(50);
    font-weight: bold;
    margin-bottom: 20px;
  }
  .content {
    :deep(p) {
      line-height: r(50);
      font-size: r(40);
      margin-bottom: r(60);
    }

    :deep(.bad) {
      background: red;
      padding: 4px;
      color: #fff;
    }
    :deep(.warning) {
      background: yellow;
      padding: 4px;
      color: #000;
    }
  }
  .down-btn {
    position: fixed;
    right: 10px;
    top: 10px;
    display: flex;
    :deep(.van-button) {
      margin-right: r(20);
    }
  }
}
</style>